import { useEffect, useState } from "react";
import "twin.macro";
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";

const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

export default ({ configImages , branch }) => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const getBanner = () => {
      configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "shippingPolicy") {
          setBanner(data.value);
        }
      });
    };


    getBanner();
    //eslint-disable-next-line
  }, []);


  let  Site_Url = "https://saravanasjewellers.in/";

  const list = [
    {
      title: "Shipping Policy",
      description: `We value what you value. And indeed for us Jewels value more than its physical value. ${branch.storeName} always anchors this principle in its value system and takes utmost care in honoring that. Please read carefully about our company’s terms and get to know what and how we serve our customers.`,
      show: "show",
    },
    {
      title: "Delivery Schedule",
      description: `${branch.storeName} aims to deliver the shipments in 7 to 21 working days, subject to the availability (Refer ’Product Availability’ section) of the products in the showrooms. There may be some delay which may take more than 7 to 21 working days to procure or to design. In such case, the customer will be promptly communicated and made aware of the situation. Delivery guarantee is subject to the terms and conditions of the shipping agent or the courier company. Any inaccuracy in the name or address or inconsistency of the recipient’s presence during delivery shall result in non delivery of the product. Estimated duration for delivery indicated in Terms and Conditions commence from the date of dispatch and are for guidance purpose only and may vary invariably. The order will be processed to shipping only after the credit/debit card number given/provided is approved by the bank.`,
      show: "",
    },
    {
      title: "Shipment Procedure",
      description: `The shipment shall be delivered only to the specified delivery address confirmed by the user. Customers have the option of changing the delivery address any number of times before the order is processed for shipment. Once the shipment is processed, neither the specified receiver of the delivery nor the specified address for delivery shall be changed. Any number of products ordered for a single delivery address will be bundled together and shipped as single shipment.
  
      In case of shipping the ’gifts’, the beneficiary must produce their proof of identification recognized as identification proof by the Government of India. Signature of the recipient is considered as acknowledgment and for verification.
      
      The support team of ${branch.storeName} may contact you to confirm your address, availability etc before shipping the product. The shipment can be tracked by the customer by checking with the courier agent. We are not responsible for any delays caused by courier agencies and/or due to time required for statutory clearances during the delivery process.`,
      show: "",
    },
    {
      title: "Force Majeure",
      description: `Any delay or failure in the delivery hereunder shall be excused and ${branch.storeName} is not responsible if and to the extent caused by the occurrence of a Force Majeure. For purposes of this Agreement, Force Majeure shall mean a cause or event that is not reasonably foreseeable or otherwise caused by or under the control of the Parties (includes ${Site_Url} and courier company) claiming Force Majeure, including miraculous acts of God, natural disasters or emergencies like fires, floods, explosions, riots, wars, hurricane, sabotage terrorism, vandalism, accident, restraint of government, governmental acts or parliamentary restrictions, prohibitions, any enactments on import or export regulation or regulations on exchanges foreign as well as domestic, injunctions, or unavailability to transit, labour strikes, other than those of Seller, the ${branch.storeName} or its Courier agent, that prevent us from delivering the materials and other like events that are beyond the reasonable anticipation and control of the company affected thereby, despite such company’s reasonable efforts to prevent, avoid, delay, or mitigate the effect of such acts, events or occurrences, and which events or the effects thereof are not attributable to a failure to deliver its obligations under this Agreement.`,
      show: "",
    },
    {
      title: "Return Shipments",
      description:
        "If you suspect that the shipment delivered to you to be ’not in good condition’ or ’attempted for tampering’ or if the package of shipment is tampered, you shall refuse to acknowledge the receipt and return the same before accepting the shipped package.",
      description2:
        `In case, the authorized recipient is unavailable to receive and acknowledge the package, the courier agent may try to deliver twice after the first delivery as per their policy. If the recipient didn’t receive in all the three attempts, the package will be shipped back to the office of ${branch.storeName} and all the cost incurred through the shipment shall be borne by the customer along with the handling charges. The customer shall also bear the shipping charges when shipped again. Refer ’Cancellation Terms’ section if in case the customer cancels after the failed delivery.`,
      show: "",
    },
    {
      title: "Packaging",
      description: `${branch.storeName} takes special care in packaging user’s precious purchase. The jewels purchased are secured in a shockproof metal box, layered with durable and tamper proof seal from ${branch.storeName}. Every packing is video covered for security reason. If user requests for special gift pack, ${Site_Url} will gift wrap it along with the words you wish your recipient to receive.    `,
      show: "",
    },
    {
      title: "Cancellation Terms",
      description: `Users will be informed if their order is aborted or cancelled either partially or fully and the company may request for additional information if required. Cancellation of order shall happen either by the customer or by the company.`,
      show: "",
    },
    {
      title: "Cancellation from Customer",
      description: `To cancel an order you have to send cancellation notice via email only mentioning the Order Id. User can cancel the order for any genuine reason at any time regardless of the shipment status. Customized jewels shall not be entertained for cancellation before or after delivery. We reserve the right to accept or reject requests for order cancellations for any reason`,
      description2: `The user agrees not to dispute the decision made by us and accepts our decision as final, in this regard. As part of usual business practice, if we receive a cancellation notice the full invoice value will be refunded to you to the same bank account through cheque or internet banking from where the payment was originally effected.`,
      show: "",
    },
    {
      title: "Cancellation by Company",
      description: `Situation may arise that ${Site_Url} may not be in a position to accept an order and hence left with no other alternative except cancellation. ${branch.storeName} reserves sole discretionary right to refuse or cancel an order for any reason which the management considers appropriate. Few of the situations that lead to the cancellation of your order includes the unavailability of the product, problems identified by credit and fraudulence investigation of fraud avoidance department, inaccuracies or errors in product or pricing information etc,. If the order is cancelled by the company, after your credit/debit card is charged or if payment is processed through internet banking, the charged /paid amount will be refunded back to the same account. The cancellation of the order may be either in full or partial(cases where you purchased more than an item and purchase of one item is cancelled by the company) and the information about cancellation will be informed to the customer and, if required, ${branch.storeName} shall clarify the required information.`,
      show: "",
    },
    {
      title: "Fraudulent Transactions",
      description: `${branch.storeName} reserves every right and shall reclaim the product cost, processing charges, shipping cost, legal expenses and other such cost from the user who is involved in a fraudulent transaction.`,
      show: "",
    },
    {
      title: "Disclaimer",
      description: `The information contained in this website is for general information purposes only. Neither ${branch.storeName}, nor its directors, members, employees, agents, any third party, data or content provider shall be liable in any way to you or to any other third party, firm or corporation without limitation whatsoever for any loss, liability, damage (whether direct, indirect, special or consequential), personal injury, risk of harm or expense of any nature whatsoever arising from any delays in operation, inaccuracies, mistakes, errors in, deletion of files or email, defects, viruses, or any failure of performance, whether or not resulting from miraculous acts of God, communication failure, theft, destruction, or omission of any share price information ,or transmission thereof or for any actions taken in reliance thereon or occasioned thereby or by reason of non-performance or interruption, or termination thereof arising out of unauthorized access to the records, programs or services of ${branch.storeName} Limited, or in any way connected with the use of our website, the products and services, or the content contained in or accessed through our website.`,
      show: "",
    },
    {
      title: "Financial Transaction",
      description: `We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on account of the cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.`,
      show: "",
    },
    {
      title: "Links and external Materials",
      description: `The website may contain links to other websites; these external websites are not under our control. We cannot be held responsible for such websites and cannot make any guarantees about them. We provide these links because we think they might interest you, but we do not monitor or endorse these other Websites.`,
      show: "",
    },
    {
      title: "On Modification",
      description: `We reserve the right to terminate or remove any of the services on website with immediate effect at any time, for example if there is a change in the law that limits our ability to provide the website.`,
      show: "",
    },
    {
      title: "Social Network",
      description: `As per the current regulations of Government of India, all Jewels be it Gold, Silver, Diamond , Platinum or any other precious metal with or without precious or semi precious stones that are billed will fall within the scope of GST, which shall subsume the tax structure that had been in force till June 2017.`,
      show: "",
    },
    {
      title: "Liability",
      description: `${branch.storeName} shall not take responsibility or liability for any loss or damage suffered by ordering online or through freight transit for the purchased product, except as required by law, even if it is argued that we could have foreseen the loss or the possibility of it brought to our attention.`,
      show: "",
    },
    {
      title: "Maintenance of the Website",
      description: `Every effort is made to keep the website up and running smoothly. However, ${branch.storeName} takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.    `,
      show: "",
    },
    {
      title: "Legal Actions",
      description: `Any user who breaches the terms and conditions or involves in any credit/debit card or internet banking scam or considered to violate any act considered as violation of Indian Contract Act, 1872 shall face the consequence legally under similar acts applicable. Legal proceedings shall be initiated against the user who is involved in such fraudulent transaction or any unlawful act.`,
      show: "",
    },
    {
      title: "Rights About The Content",
      description: `${branch.storeName} reserves and holds all the rights of the contents on the website. The content(s) of the website, including texts, design(s), photograph(s), model(s), trademark(s) etc are the intellectual property of ${branch.storeName} and shall not be copied, edited, modified, misused, repositioned, translated or transcribed, rented or sold by any means.
      Usage of the content(s) or service(s) shall not be displayed, utilized in any way other than personal viewing, either for commercial or non –commercial purpose. Kindly do check Copyrights and Trademark section.`,
      description2: `${branch.storeName} holds non exclusive, royalty - free and irrevocable rights to use, modify, adapt, translate, create derivative works, publish, display, distribute the contents of the users or members such as feedback, comments, mails etc.`,
      show: "",
    },
    {
      title: "Website Contents",
      description: `Without any limitations, the texts, the images and all other content that includes the corporate logo, the photographs, models, audio, video, the ’look and feel’ of the website, the color texture and coordination, the graphics, the software, the database, the user interface, the Graphical User Interface, promotional files such as images, audio tunes, video files, the designs, along with product designs and thereof shall collectively be called the ’website content’. The ’website content’ is a creative work of ${branch.storeName}, vendors, and corporate associates. Any resemblance of the content with others is unintentional and co-incidental.`,
      show: "",
    },
    {
      title: "Ownership of Content, Copyrights and Trademark",
      description: `${branch.storeName} is entitled to ownership of ’Website Content’ as defined in the passages ’Website Content & Feedbacks, Reviews and Customer Interaction’. ${branch.storeName} shall be considered as the owner of social media page of ${Site_Url} and shall be considered as owner for these contents and also reserves right over the member’s feedback, suggestion, complaints.`,
      description2: `This content shall not be published, displayed, distributed, transferred, modified, plagiarized, sold or exploited in any way either partly or fully . All software used in the website is property of ${Site_Url} or their vendors and under copyrights protection act of India or Indian copyrights Act (ICA) and other applicable laws and are protected by Indian and international copyrights law.`,
      show: "",
    },
    {
      title: "Third Party Content",
      description: `In the website of ${Site_Url}, users may find few links leading to ’third party’ websites. It could be advertisement, commercial or informational websites. However, ${Site_Url} doesn’t endorse the content of those sites for its accuracy, legitimacy or genuineness. The user alone is responsible for the consequences that may arise as a result of trusting those third party content.`,
      show: "",
    },
    {
      title: "Communications",
      description: `${branch.storeName} is interested in connecting with customers and constantly promoting the satisfaction of its customers by being receptive to their suggestions and feedbacks. Feel free to contact our office if you require any clarification regarding the Terms and Conditions or about the website and its contents. Our team will eagerly respond to your queries.`,
      show: "",
    },
    {
      title: "Electronic Communications",
      description: `Communication with ${Site_Url} may be carried out through email or SMS or telephone. The mail ID & toll free number can be conspicuously seen in the header or footer sections of the website. The customer support personnel shall be available from 10 am to 7 pm Indian Standard Time (GMT +5.30 Hrs). Customer support or sales support staff are capable of educating the user on the product or terms and conditions when inquired.`,
      show: "",
    },
    {
      title: "Feedbacks, Reviews and Customer Interaction",
      description: `User can also connect with us and share their shopping experience on our website ${Site_Url} or in social media. To get regular updates and news feeds about ${branch.storeName}, please “Like” our Facebook page www.facebook.com/${branch.storeName}. User may give feedback on the products or services of ${branch.storeName} or share their reviews on discussion forum. Users who provide feedback or review or participate in customer interaction sections or pages agree that they refrain from posting, uploading, distributing, storing any content/text/picture/video/audio or manipulate any existing web content or other user content that may be considered as unlawful, unethical, obscene, explicit, suggestive, of pornographic nature or threatening or intruding privacy or harassing, abusive or cheating etc to ${Site_Url} or its social network page.
  
      Users shall also desist from posting any content that are intellectual property such as copyrights, trademarks, business process, patents or trade secrets of others or any third party. They shall never indulge in any unsolicited campaign like marketing or promotional or political campaign of any kind. They should never post any links or contents that are harmful to the website users like virus, spy wares, or destructive files.
      
      ${Site_Url} assumes no responsibility over the user comment or interactions on any of its interactive pages. ${Site_Url} shall not owe an answer to any of the user’s comment and may or may not remove the user or user’s comments. User is solely responsible for such comments, reviews, suggestions, feedback, ideas posted, submitted or disclosed (shall be defined as interactions) to ${Site_Url}. ${Site_Url} reserves exclusive rights for all the content (interactions) as the user agrees to a royalty free, irrevocable and fully licensable right and ${branch.storeName} shall in anyway use it either for commercial or other way and is entitled to use, edit, modify, create, recreate, or create derivative work, adapt and publish, promote, market, distribute or display it through any media any where in the world.`,
      show: "",
    },
    {
      title: "Indemnification",
      description: `You agree to defend ${branch.storeName}, its management, directors, employees, consultants, agents, shareholders regarding the content posted on the interactive pages from and against any claims and expenses that may also include legal actions, for your violation against any party and against the Terms and Conditions of the ${branch.storeName}.`,
      show: "",
    },
  ];


  return (
    <div
      tw="relative font-display overflow-hidden"
      className="custom_background"
    >
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Shipping Policy" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <Heading>SHIPPING POLICY</Heading>
      <div className="container py-2 py-sm-5">
        <div className="accordion" id="accordionExample">
          {list.map((data, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
              <button
                  className={`accordion-button text-uppercase`}
                  //  ${
                  //   data.show === "" ? "collapsed" : "show"
                  // }`}
                  type="button"
                  data-bs-toggle="collapse"
                  // data-bs-target={`#one${index}`}
                >
                  {data.title}
                </button>
              </h2>
              <div
                id={`one${index}`}
                className={`accordion-collapse collapse ${
                  // data.show === "" ? "collapsed" :
                   "show"
                } ptSans`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>{data.description}</p>
                  <p>{data.description2}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
